import React, { FC, useState } from 'react';
import Link from 'next/link';

interface IAdminLinkPanel {
  isMobile: boolean;
  changeSelectedItem(arg: number | null): void;
  index: number;
  isActive: boolean;
}

const AdminLinkPanel: FC<IAdminLinkPanel> = ({ changeSelectedItem, isMobile, index, isActive }): React.ReactElement => {
  const [menu] = useState([
    {
      editing: [
        { label: 'Articles', link: '/admin/article' },
        { label: 'Help', link: '/admin/help' },
      ],
    },
  ]);

  const clickItemHandler = (argument: number) => {
    if (isActive) {
      changeSelectedItem(null);
      return;
    }

    changeSelectedItem(argument);
  };

  return (
    <>
      {!isMobile ? (
        <div className="header__nav-item--sublink">
          <svg
            className="admin__lock"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M17,9c0-0.4,0-1.6,0-2c0-2.8-2.2-5-5-5C9.6,2,7.6,3.7,7.1,6"
            />
            <path d="M14,15c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S14,13.9,14,15z" />
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M18,21H6c-0.552,0-1-0.448-1-1V10c0-0.552,0.448-1,1-1h12c0.552,0,1,0.448,1,1v10C19,20.552,18.552,21,18,21z"
            />
          </svg>
          <ul className={`header__sublink fr-${menu.length}`}>
            {menu.map((data, index) => {
              return (
                <li key={index} className="header__sublink-group">
                  {Object.entries(data).map(([title, sublinks]) => {
                    return (
                      <React.Fragment key={title}>
                        <h4 className="header__sublink-title">{title.split('_').join(' ')}</h4>
                        {sublinks.map(
                          (
                            subItem: {
                              link: string | undefined;
                              label:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                            },
                            i: React.Key | null | undefined
                          ) => {
                            return (
                              <Link className="header__sublink-link" key={i} href={subItem?.link || ''}>
                                {subItem.label}
                              </Link>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <>
          <div className={`header__nav-item--sublink ${!isActive || 'active'}`} onClick={() => clickItemHandler(index)}>
            <svg
              className="admin__lock"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            >
              <path
                fill="none"
                stroke="#000000"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M17,9c0-0.4,0-1.6,0-2c0-2.8-2.2-5-5-5C9.6,2,7.6,3.7,7.1,6"
              />
              <path d="M14,15c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S14,13.9,14,15z" />
              <path
                fill="none"
                stroke="#000000"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M18,21H6c-0.552,0-1-0.448-1-1V10c0-0.552,0.448-1,1-1h12c0.552,0,1,0.448,1,1v10C19,20.552,18.552,21,18,21z"
              />
            </svg>
          </div>
          <div className={`header__sublink-box ${!isActive || 'active'}`}>
            {menu[0].editing.map(({ label, link }, index) => {
              return (
                <React.Fragment key={`${label}_${index}`}>
                  <Link className="header__sublink-link" href={link}>
                    {label}
                  </Link>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default AdminLinkPanel;
